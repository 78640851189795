@use 'mixins' as *;

/* Main */
:root {
  --header-height: 64px;
  --map-offset: 24px;
  --map-width: 100%;
  --map-height: 500px;
  --map-height-desktop: 600px;
  --page-margin: 1rem;
  --page-margin-negative: -1rem;
  --section-spacing: 3rem;
}

@include breakpoint-min(medium) {
  :root {
    --page-margin: 2rem;
    --page-margin-negative: -2rem;
    --section-spacing: 3rem;
  }
}

/* Colors */
:root {
  --color-black: #000;
  --color-grey1: rgb(247, 247, 247);
  --color-grey2: rgb(234, 234, 234);
  --color-grey2-hover: rgb(208, 210, 212);
  --color-grey3: rgb(208, 210, 212);
  --color-grey4: rgb(124, 124, 124);
  --color-grey4b: rgb(97, 97, 97);
  --color-grey5: rgb(64, 64, 64);
  --color-hell-salmon: #fa826e;
  --color-overlay: rgba(0, 0, 0, 0.3);
  --color-system-active: rgb(108, 135, 205);
  --color-system-black: rgb(17, 17, 17);
  --color-system-ok: rgb(116, 202, 140);
  --color-system-warning: rgb(225, 95, 85);
  --color-white: #ffffff;
  --color-wibe-dark-grey: rgb(48, 62, 71);
  --color-wibe-dark-grey-hover: rgb(17, 17, 17);
  --color-wibe-dark-steel: rgb(140, 150, 157);
  --color-wibe-dark-steel-hover: rgb(125, 135, 142);
  --color-wibe-green: rgb(120, 170, 100);
  --color-wibe-green-alpha: rgba(120, 170, 100, 0.9);
  --color-wibe-light-steel: rgb(216, 224, 228);
  --color-wibe-light-steel-hover: rgb(202, 210, 214);
  --color-wibe-new: rgb(54, 182, 176);
  --color-wibe-superlight-steel: rgb(243, 246, 247);
  --color-wibe-superlight-steel-hover: rgb(247, 247, 247);
  --color-wibe-yellow: rgb(255, 205, 0);
  --color-wibe-yellow-alpha: rgba(255, 205, 0, 0.9);
  --color-wibe-yellow-hover: rgb(251, 217, 77);
}

/* Spacing */
:root {
  --spacing-l: 48px;
  --spacing-m: 24px;
  --spacing-mega: 17.5rem;
  --spacing-menu-height: 56px;
  --spacing-none: 0;
  --spacing-s: 16px;
  --spacing-side: 32px;
  --spacing-xl: 96px;
  --spacing-xs: 8px;
  --spacing-xss: 12px;
  --spacing-xxl: 192px;
  --spacing-xxs: 4px;
  --spacing-l-negative: -48px;
  --spacing-m-negative: -24px;
  --spacing-mega-negative: -17.5rem;
  --spacing-menu-height-negative: -56px;
  --spacing-none-negative: -0;
  --spacing-s-negative: -16px;
  --spacing-side-negative: -32px;
  --spacing-xl-negative: -96px;
  --spacing-xs-negative: -8px;
  --spacing-xss-negative: -12px;
  --spacing-xxl-negative: -192px;
  --spacing-xxs-negative: -4px;
}

/* Typography */
:root {
  --font-size-header1: 2.5rem;
  --font-size-header2: 1.875rem;
  --font-size-header3: 1.5rem;
  --font-size-header4: 1.25rem;
  --font-size-header5: 1.125rem;
  --font-size-mega1: 3.5rem;
  --font-size-mega2: 2.25rem;
  --font-size-paragraph10: 0.625rem;
  --font-size-paragraph12: 0.75rem;
  --font-size-paragraph14: 0.875rem;
  --font-size-paragraph16: 1rem;
  --font-weight-extra-bold: 800;
  --font-weight-light: 300;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-weight-thin: 100;
  --line-height-header1: 44px;
  --line-height-header2: 34px;
  --line-height-header3: 28px;
  --line-height-header4: 26px;
  --line-height-header5: 24px;
  --line-height-mega1: 58px;
  --line-height-mega2: 40px;
  --line-height-paragraph10: 14px;
  --line-height-paragraph12: 16px;
  --line-height-paragraph14: 18px;
  --line-height-paragraph16: 24px;
  --font-family: var(--font-family);
}

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  background-repeat: no-repeat; /* 2 */
}
html {
  font-size: 16px;
  min-height: 100vh;
  /* scroll-behavior: smooth; */ // Seem to be known issue in next.js and preventing scroll to top
}

body {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 64px 0 0;
}

#root {
  min-height: 100vh;
}

img {
  max-width: 100%;
}

.no-js img.lazyload {
  display: none;
}

.map {
  position: relative;
  width: 100%;
}
h1,
h2,
h3,
h4,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}
.loadingAnimation {
  animation-name: pulse;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

.text {
  &__p16 {
    font-size: var(--font-size-paragraph16);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-paragraph16);
  }

  &__p16-medium {
    font-size: var(--font-size-paragraph16);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-paragraph16);
  }

  &__p14 {
    font-size: var(--font-size-paragraph14);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-paragraph14);
  }

  &__p14-medium {
    font-size: var(--font-size-paragraph14);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-paragraph14);
  }

  &__p12 {
    font-size: var(--font-size-paragraph12);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-paragraph12);
  }

  &__p12-medium {
    font-size: var(--font-size-paragraph12);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-paragraph12);
  }

  &__p10 {
    font-size: var(--font-size-paragraph10);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-paragraph10);
  }

  &__label {
    font-size: var(--font-size-paragraph16);
    font-weight: var(--font-weight-medium);
    letter-spacing: var(--letter-spacing-heroLabel);
    line-height: var(--line-height-paragraph16);
    text-transform: uppercase;
  }

  &__label-hero {
    font-size: var(--font-size-paragraph16);
    font-weight: var(--font-weight-extraBold);
    letter-spacing: var(--letter-spacing-heroLabel);
    line-height: var(--line-height-paragraph16);
    text-transform: uppercase;
  }

  &__header4 {
    font-size: var(--font-size-header4);
    font-weight: var(--font-weight-light);
    letter-spacing: var(--letter-spacing-header4);
    line-height: var(--line-height-header4);
  }
}
